import React, { useState, useEffect, useRef } from 'react';
import axios from 'utils/axios';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Grid,
    Button,
    Typography,
    Stack,
    InputAdornment,
    Box,
    Card,
    useMediaQuery,
    LinearProgress,
    CardContent,
    Divider,
    Chip,
    Avatar
} from '@mui/material';
import JsBarcode from 'jsbarcode';
import MainCard from 'ui-component/cards/MainCard';
import { useTheme } from '@mui/material/styles';
import PedidoPdf from './components/PedidoPdf';
import ReactToPrint from 'react-to-print';
import GuiaReact from './components/GuiaReact';
import { namedStringDate, namedDateFull } from 'utils/helpers';
import ActividadColumn from './components/ActividadColumn';
import { styled } from '@mui/material/styles';

import HomeIcon from '@mui/icons-material/Home';
import CallMadeIcon from '@mui/icons-material/CallMade';
import boxIcon from 'assets/images/icons/box-icon.png';
import FactCheckRoundedIcon from '@mui/icons-material/FactCheckRounded';
import CurrencyFormat from 'react-currency-format';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import MonetizationOnTwoToneIcon from '@mui/icons-material/MonetizationOnTwoTone';
import OtherHousesTwoToneIcon from '@mui/icons-material/OtherHousesTwoTone';
import PlaceTwoToneIcon from '@mui/icons-material/PlaceTwoTone';
import { shouldForwardProp } from '@mui/system';
import { IconPrinter, IconDownload, IconEdit } from '@tabler/icons';
import ComprobanteDialog from './components/ComprobanteDialog';

const IconWrapper = styled('div')({
    position: 'absolute',
    right: '-41px',
    top: '-47px',
    color: '#fff',
    zIndex: 'modal',
    transform: 'rotate(-25deg)',
    '&> svg': {
        width: '200px',
        height: '200px',
        opacity: '0.4'
    }
});
const IconWrapperMed = styled('div')({
    position: 'absolute',
    right: '-31px',
    top: '-37px',
    color: '#fff',
    zIndex: 'modal',
    transform: 'rotate(-25deg)',
    '&> svg': {
        width: '180px',
        height: '180px',
        opacity: '0.4'
    }
});

const CardWrapper = styled(MainCard)(({ theme, color }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.secondary.dark,
    color: '#fff',
    overflow: 'hidden',
    position: 'relative',
    borderRadius: 0,
    '&:after': {
        content: '""',
        position: 'absolute',
        width: 395,
        height: 345,
        background: color,
        transform: 'rotate(-20deg)',
        top: -205,
        left: -175,
        opacity: 1,
        [theme.breakpoints.down('sm')]: {
            top: -105,
            right: -140,
            transform: 'rotate(-25deg)'
        }
    }
}));

const PedidoView = ({ guia, data, standalone }) => {
    let params = useParams();
    const theme = useTheme();
    const small = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();

    const [pedidoId, setPedidoId] = useState(params.id);
    const [openBarcode, setOpenBarcode] = React.useState(false);
    const [pedidoData, setPedidoData] = React.useState({});
    const [colors, setColors] = React.useState({ estatusBg: '#424242' });
    const [p, setP] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(false);
    const [comprobanteData, setComprobanteData] = React.useState({});
    const [openDialog, setOpenDialog] = React.useState(false);

    React.useEffect(() => {
        if (standalone) {
            var noGuia;
            if (!guia) {
                noGuia = params.id;
            } else {
                noGuia = guia;
            }
            fetchData(noGuia);
        } else {
            childrenData(data);
        }
    }, []);

    const fetchData = async (pedido) => {
        setIsLoading(true);
        const apiUrl = `/api/v1/pedidos/pedidofullfull/${pedido}`;
        //const apiUrl = `/api/v1/pedidos/pedidofulldatetest/${pedido}`;

        //console.log(apiData);
        await axios.get(apiUrl).then((response) => {
            console.log(response.data);
            if (response.data.guia.estatus_id === 1) {
                setColors({ ...colors, estatusBg: '#203461' });
            } else if (response.data.guia.estatus_id === 4) {
                setColors({ ...colors, estatusBg: '#019267' });
            } else if (response.data.guia.estatus_id === 5) {
                setColors({ ...colors, estatusBg: '#E64848' });
            }
            console.log(namedDateFull(new Date(response.data.actividad[0].act_dtime)));
            setP(response.data.guia);
            setPedidoData(response.data);
            setIsLoading(false);
            const pg = response.data.guia;
            if (pg.forma_pago_id === 2) {
                let imgType = 0;
                if (p.comp_url) {
                    var c = p.comp_url.toLowerCase();
                    if (c.includes('.pdf')) {
                        imgType = 2;
                    } else {
                        imgType = 1;
                    }
                } else {
                    imgType = 0;
                }

                const compData = {
                    comp: pg.comp,
                    comp_url: pg.comp_url,
                    compania: pg.cliente_empresa,
                    correo: pg.cliente_correo,
                    id: pg.id,
                    imgType: imgType,
                    nombre: pg.cliente_nombre,
                    origen_contacto_tel: pg.origen_contacto_tel,
                    origen_contacto_nombre: pg.origen_contacto_nombre,
                    precio_venta: pg.precio_venta,
                    sysId: pg.id,
                    sysop_esid: pg.estatus_id,
                    telefono: pg.cliente_telefono
                };
                console.log(compData);
                setComprobanteData(compData);
            }
        });
    };

    const childrenData = (data) => {
        console.log(data);
        if (data.guia.estatus_id === 1) {
            setColors({ ...colors, estatusBg: '#203461' });
        } else if (data.guia.estatus_id === 4) {
            setColors({ ...colors, estatusBg: '#019267' });
        } else if (data.guia.estatus_id === 5) {
            setColors({ ...colors, estatusBg: '#E64848' });
        }
        console.log(namedDateFull(new Date(data.actividad[0].act_dtime)));
        setP(data.guia);
        setPedidoData(data);

        const pg = data.guia;
        if (pg.forma_pago_id === 2) {
            let imgType = 0;
            if (pg.comp_url) {
                var c = pg.comp_url.toLowerCase();
                if (c.includes('.pdf')) {
                    imgType = 2;
                } else {
                    imgType = 1;
                }
            } else {
                imgType = 0;
            }

            const compData = {
                comp: pg.comp,
                comp_url: pg.comp_url,
                compania: pg.cliente_empresa,
                correo: pg.cliente_correo,
                id: pg.id,
                imgType: imgType,
                nombre: pg.cliente_nombre,
                origen_contacto_tel: pg.origen_contacto_tel,
                origen_contacto_nombre: pg.origen_contacto_nombre,
                precio_venta: pg.precio_venta,
                sysId: pg.id,
                sysop_esid: pg.estatus_id,
                telefono: pg.cliente_telefono
            };
            console.log(compData);
            setComprobanteData(compData);
        }
    };

    let canvas;
    canvas = document.createElement('canvas');
    JsBarcode(canvas, pedidoId, {
        width: 2,
        height: 60,
        displayValue: false
    });
    const barcode = canvas.toDataURL();

    const handleBarcodeDialog = () => {
        setOpenBarcode(true);
    };

    const closeBarcodeDialog = (val) => {
        console.log(val);
        setOpenBarcode(false);
    };

    const sendToPrint = (guia) => {
        window.open(`/searchprint/${guia}`, '_blank');
    };

    const sendToPrintZebra = (guia) => {
        window.open(`/searchprintzebra/${guia}`, '_blank');
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <Card sx={{ px: small ? 1 : 2, borderRadius: 0 }}>
            {isLoading && <LinearProgress sx={{ my: 1 }} />}
            {pedidoData.guia && (
                <Grid container sx={{ m: 0 }}>
                    <ComprobanteDialog open={openDialog} handleCloseDialog={handleCloseDialog} data={comprobanteData} loading={isLoading} />
                    <Grid item xs={12} lg={8} sx={{ mb: 1, mx: 1, position: 'relative', zIndex: 900 }}>
                        <Stack direction="row" alignItems="flex-end" justifyContent="space-between" sx={{ mb: 2 }}>
                            <Stack direction="row" alignItems="center">
                                <Stack direction="column" sx={{ m: 0 }}>
                                    <Typography variant={small ? 'h3' : 'h1'} sx={{ fontSize: 40, my: 0 }}>
                                        {p.id}
                                    </Typography>
                                    <div style={{ display: 'flex' }}>
                                        <Typography
                                            variant="caption"
                                            sx={{
                                                py: 0.6,
                                                px: 0.8,
                                                fontWeight: 600,
                                                fontSize: 12,
                                                lineHeight: 1,
                                                backgroundColor: '#FFF',
                                                borderRadius: 1,
                                                color: colors.estatusBg,
                                                display: 'flex'
                                            }}
                                        >
                                            {p.estatus}
                                        </Typography>
                                    </div>
                                </Stack>

                                <InputAdornment position="end" onClick={() => sendToPrint(p.id)} sx={{ ml: 8 }}>
                                    <HeaderAvatarStyle variant="rounded">
                                        <IconPrinter stroke={2} size="1.5rem" sx={{ p: 1 }} />
                                    </HeaderAvatarStyle>
                                </InputAdornment>
                                <InputAdornment position="end" onClick={() => sendToPrintZebra(p.id)} sx={{ ml: 2 }}>
                                    <HeaderAvatarStyle variant="rounded" sx={{ width: 100 }}>
                                        <IconPrinter stroke={2} size="1.5rem" sx={{ p: 1 }} />
                                        <Typography variant="h6" sx={{ ml: 1 }}>
                                            Zebra
                                        </Typography>
                                    </HeaderAvatarStyle>
                                </InputAdornment>
                            </Stack>
                            <Stack direction="column" sx={{ m: 0 }}>
                                <Typography variant="h6" align="right" sx={{ my: 0 }}>
                                    {p.cliente_nombre}
                                </Typography>
                                <Typography variant="smallsub" align="right" sx={{ my: 0 }}>
                                    No. cliente: {p.sysop_cli}
                                </Typography>
                                <Typography variant="smallsub" align="right" sx={{ my: 0 }}>
                                    {p.cliente_correo}
                                </Typography>
                                <Typography variant="smallsub" align="right" sx={{ my: 0 }}>
                                    {p.cliente_telefono}
                                </Typography>
                                <Typography variant="h6" align="right" sx={{ my: 0, color: theme.palette.info.main }}>
                                    {p.cliente_empresa}
                                </Typography>
                            </Stack>
                        </Stack>
                        <Stack direction={small ? 'column' : 'row'} justifyContent="space-between" sx={{ mx: 0, my: 2 }}>
                            <Box
                                sx={{
                                    py: 2,
                                    px: 2,
                                    bgcolor: theme.palette.grey[300],
                                    position: 'relative',
                                    width: small ? '100%' : '48%',
                                    borderRadius: 2
                                }}
                            >
                                <IconWrapper>
                                    <OtherHousesTwoToneIcon fontSize="large" />
                                </IconWrapper>
                                <Stack direction="column" sx={{ mr: 2, position: 'relative', zIndex: 920 }}>
                                    <Stack direction="row" alignItems="center">
                                        <Typography variant={small ? 'h4' : 'h3'} sx={{ my: 0, mx: 0, fontWeight: 800 }}>
                                            Recolección
                                        </Typography>
                                    </Stack>

                                    <Typography variant="body2" sx={{ mt: 0, fontSize: 12, fontWeight: 600 }}>
                                        {p.origen_contacto_nombre}
                                    </Typography>
                                    <Typography variant="smallbody" sx={{ my: 0 }}>
                                        {p.origen_empresa ? p.origen_empresa : '-'}
                                    </Typography>
                                    <Typography variant="smallbody" sx={{ my: 0 }}>
                                        {p.origen_calle} {p.origen_numero} {p.origen_numero_int ? 'Int. ' + p.origen_numero_int : ''}
                                    </Typography>
                                    <Typography variant="smallbody" sx={{ my: 0 }}>
                                        {p.origen_colonia}
                                    </Typography>
                                    <Typography variant="smallbody" sx={{ my: 0 }}>
                                        {p.origen_municipio}, N.L, {p.origen_codigo_postal}
                                    </Typography>
                                    <Typography variant="smallbody" sx={{ my: 0 }}>
                                        {p.origen_contacto_tel}
                                    </Typography>
                                    <Box sx={{ height: 40, mt: 1 }}>
                                        <Typography variant={'smallbody'} sx={{ my: 0, fontWeight: 600 }}>
                                            Referencias: {p.origen_referencias}
                                        </Typography>
                                    </Box>
                                    <Stack direction="row" alignItems="center" sx={{ mt: 2, mx: 0 }}>
                                        <AccessTimeFilledIcon sx={{ color: '#323232', fontSize: 28 }} />
                                        <Stack sx={{ ml: 1 }}>
                                            <Typography variant="h5" sx={{ mt: 0, lineHeight: 1 }}>
                                                {p.origen_fecha ? namedStringDate(p.origen_fecha) : ''}
                                            </Typography>
                                            <Typography variant="body2" sx={{ my: 0 }}>
                                                Bloque: {p.origen_bloque}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Box>

                            <Box
                                sx={{
                                    py: 2,
                                    px: 2,
                                    bgcolor: theme.palette.grey[300],
                                    my: small ? 1 : 0,
                                    position: 'relative',
                                    width: small ? '100%' : '48%',
                                    borderRadius: 2
                                }}
                            >
                                <IconWrapper>
                                    <PlaceTwoToneIcon fontSize="large" />
                                </IconWrapper>
                                <Stack direction="column" sx={{ ml: 0, position: 'relative', zIndex: 920 }}>
                                    <Stack direction="row" alignItems="center">
                                        <Typography variant={small ? 'h4' : 'h3'} sx={{ my: 0, mx: 0, fontWeight: 800 }}>
                                            Entrega
                                        </Typography>
                                    </Stack>

                                    <Typography variant="body2" sx={{ mt: 0, fontSize: 12, fontWeight: 600 }}>
                                        {p.entrega_contacto_nombre}
                                    </Typography>
                                    <Typography variant="smallbody" sx={{ my: 0 }}>
                                        {p.entrega_empresa ? p.entrega_empresa : '-'}
                                    </Typography>
                                    <Typography variant="smallbody" sx={{ my: 0 }}>
                                        {p.entrega_calle} {p.entrega_numero} {p.entrega_numero_int ? 'Int. ' + p.entrega_numero_int : ''}
                                    </Typography>
                                    <Typography variant="smallbody" sx={{ my: 0 }}>
                                        {p.entrega_colonia}
                                    </Typography>
                                    <Typography variant="smallbody" sx={{ my: 0 }}>
                                        {p.entrega_municipio}, N.L, {p.entrega_codigo_postal}
                                    </Typography>
                                    <Typography variant="smallbody" sx={{ my: 0 }}>
                                        {p.entrega_contacto_tel}
                                    </Typography>
                                    <Box sx={{ height: 40, mt: 1 }}>
                                        <Typography variant={'smallbody'} sx={{ my: 0, fontWeight: 600 }}>
                                            Referencias: {p.entrega_referencias}
                                        </Typography>
                                    </Box>
                                    <Stack direction="row" alignItems="center" sx={{ mt: 2, mx: 0 }}>
                                        <AccessTimeFilledIcon sx={{ color: '#323232', fontSize: 28 }} />
                                        <Stack sx={{ ml: 1 }}>
                                            <Typography variant="h5" sx={{ mt: 0, lineHeight: 1 }}>
                                                {p.entrega_fecha ? namedStringDate(p.entrega_fecha) : ''}
                                            </Typography>
                                            <Typography variant="body2" sx={{ my: 0 }}>
                                                Bloque: {p.entrega_bloque}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Box>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-between"></Stack>
                        <Grid sx={{ mb: 2 }}>
                            <Box
                                sx={{
                                    py: 2,
                                    px: 2,
                                    bgcolor: theme.palette.grey[300],
                                    my: small ? 1 : 0,
                                    position: 'relative',
                                    width: '100%',
                                    borderRadius: 2
                                }}
                            >
                                <IconWrapperMed>
                                    <ViewInArIcon fontSize="large" />
                                </IconWrapperMed>
                                <Typography variant={small ? 'h4' : 'h3'} sx={{ my: 0, mx: 0, fontWeight: 800 }}>
                                    Paquete
                                </Typography>
                                <Stack direction="row" alignItems="center" sx={{ mt: 2, position: 'relative', zIndex: 920 }}>
                                    <img src={boxIcon} height={50}></img>
                                    <Stack sx={{ ml: 1 }}>
                                        <div style={{ display: 'flex' }}>
                                            <Typography
                                                variant="h1"
                                                sx={{
                                                    px: 0.5,
                                                    fontWeight: 400,
                                                    fontSize: 14,
                                                    lineHeight: 1.2,
                                                    backgroundColor: '#FF5F00',
                                                    borderRadius: 1,
                                                    color: '#FFF',
                                                    display: 'flex'
                                                }}
                                            >
                                                {p.paq_tipo}
                                            </Typography>
                                        </div>
                                        <Stack direction="row" alignItems="center" sx={{ mt: 0.5 }}>
                                            <Stack alignItems="center" sx={{ ml: 0 }}>
                                                <Typography
                                                    variant="h4"
                                                    sx={{ mt: 0, fontWeight: 800, lineHeight: 1, color: p.pn && '#fff' }}
                                                >
                                                    {p.paq_ancho}
                                                </Typography>
                                                <Typography
                                                    variant="smallsubg"
                                                    sx={{ mt: 0, fontSize: 10, color: p.en && '#fff' }}
                                                    align="right"
                                                >
                                                    ancho
                                                </Typography>
                                            </Stack>
                                            <Stack alignItems="center" sx={{ ml: 1 }}>
                                                <Typography
                                                    variant="h4"
                                                    sx={{ mt: 0, fontWeight: 800, lineHeight: 1, color: p.pn && '#fff' }}
                                                >
                                                    {p.paq_alto}
                                                </Typography>
                                                <Typography
                                                    variant="smallsubg"
                                                    sx={{ mt: 0, fontSize: 10, color: p.en && '#fff' }}
                                                    align="right"
                                                >
                                                    alto
                                                </Typography>
                                            </Stack>
                                            <Stack alignItems="center" sx={{ ml: 1, mr: 1 }}>
                                                <Typography
                                                    variant="h4"
                                                    sx={{ mt: 0, fontWeight: 800, lineHeight: 1, color: p.pn && '#fff' }}
                                                >
                                                    {p.paq_largo}
                                                </Typography>
                                                <Typography
                                                    variant="smallsubg"
                                                    sx={{ mt: 0, fontSize: 10, color: p.en && '#fff' }}
                                                    align="right"
                                                >
                                                    largo
                                                </Typography>
                                            </Stack>
                                            <div style={{ display: 'flex' }}>
                                                <Stack alignItems="center" sx={{ py: 0.2, px: 1.5, ml: 1, mr: 1, backgroundColor: '#000' }}>
                                                    <Typography
                                                        variant="h4"
                                                        sx={{ mt: 0, fontWeight: 800, lineHeight: 1, color: '#FFF', display: 'flex' }}
                                                    >
                                                        {p.paq_peso}
                                                    </Typography>
                                                    <Typography
                                                        variant="smallsubg"
                                                        sx={{ mt: 0, fontSize: 10, color: '#FFF' }}
                                                        align="right"
                                                    >
                                                        kg
                                                    </Typography>
                                                </Stack>
                                            </div>
                                        </Stack>
                                    </Stack>
                                </Stack>
                                <Box sx={{ height: 60, mt: 1 }}>
                                    <Typography variant={'smallbody'} sx={{ my: 0, fontWeight: 600 }}>
                                        Contenido: {p.paq_contenido}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Box
                            sx={{
                                py: 2,
                                px: 2,
                                bgcolor: theme.palette.grey[300],
                                my: small ? 1 : 0,
                                position: 'relative',
                                width: '100%',
                                borderRadius: 2
                            }}
                        >
                            <IconWrapperMed>
                                <MonetizationOnTwoToneIcon fontSize="large" sx={{ zIndex: 'modal' }} />
                            </IconWrapperMed>
                            <Typography variant={small ? 'h4' : 'h3'} sx={{ my: 0, mx: 0, fontWeight: 800 }}>
                                Pago
                            </Typography>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="flex-end"
                                sx={{ mx: 0, my: 0, position: 'relative', zIndex: 920 }}
                            >
                                <Stack alignItems="start" sx={{ mt: 1 }}>
                                    <Typography variant="h1" sx={{ mt: 0, fontSize: 22, fontWeight: 800 }} align="right">
                                        ${p.precio_venta}.00
                                    </Typography>
                                    <Typography variant="h1" sx={{ fontSize: 14, fontWeight: 400, mb: 0.5 }} align="right">
                                        {p.forma_pago_id == 1 ? 'EFECTIVO' : 'TRANSFERENCIA'}
                                    </Typography>
                                    <Stack direction="row">
                                        {/*  backgroundColor: colors.estatusBg,borderRadius:1 , color: "#FFF" */}

                                        <div style={{ display: 'flex' }}>
                                            <Typography
                                                variant="caption"
                                                sx={{
                                                    py: 0.3,
                                                    px: 0.6,
                                                    fontWeight: 400,
                                                    fontSize: 10,
                                                    lineHeight: 1,
                                                    backgroundColor: theme.palette.grey[500],
                                                    borderRadius: 1,
                                                    color: theme.palette.grey[300],
                                                    display: 'flex',
                                                    fontStyle: 'italic'
                                                }}
                                            >
                                                Pagado
                                            </Typography>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <Typography
                                                variant="caption"
                                                sx={{
                                                    py: 0.3,
                                                    px: 0.6,
                                                    fontWeight: 400,
                                                    fontSize: 10,
                                                    lineHeight: 1,
                                                    backgroundColor: theme.palette.grey[500],
                                                    borderRadius: 1,
                                                    color: theme.palette.grey[300],
                                                    display: 'flex',
                                                    fontStyle: 'italic',
                                                    ml: 1
                                                }}
                                            >
                                                Confirmado
                                            </Typography>
                                        </div>
                                    </Stack>
                                    <Button variant="outlined" onClick={handleOpenDialog} size="small" sx={{ mt: 1, mx: 0 }}>
                                        Ver comprobante
                                    </Button>
                                </Stack>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={3.5} sx={{ my: 1, mx: 0, pl: 1 }}>
                        <Divider>Actividad</Divider>
                        <ActividadColumn data={pedidoData.actividad} guia={pedidoData.guia} />
                    </Grid>
                </Grid>
            )}
        </Card>
    );
};

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(({ theme }) => ({
    ...theme.typography.commonAvatar,
    ...theme.typography.largeAvatar,
    background: theme.palette.grey[200],
    color: theme.palette.secondary.main,
    '&:hover': {
        background: theme.palette.grey[400],
        color: theme.palette.secondary.main
    }
}));

export default PedidoView;
